<template>
<div>
   <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: [field]})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
   <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}}
    </v-list-item>
  <v-layout class="justify-center" v-if="!Editing">
     <!-- <iframe v-if="Record[field.Name]" width="100%" :height="340" 
    :src="Record[field.Name]"></iframe> -->
     <div v-if="Record[col.Field.id]" v-html="Record[col.Field.id]">
    </div>
    <div v-if="!Record[col.Field.id]">
        NO DATA
    </div>
  </v-layout>
  <v-layout class="justify-center" v-if="Editing">
   <v-text-field v-model="Record[field.Name]" :label="field.DisplayName"/>
  </v-layout>
  <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
export default {
    props: ['System','SystemEntities','col','Record','CanEdit'],
    components: {},
    data() {
        return {
          Editing: false,
        }
    },	
    computed:{
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },

    }
}
</script>

<style>

</style>



