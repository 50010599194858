<template>
  <div>
   
    <DashUserAvatar v-if="col.Field.FieldType === 'User Avatar'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit" :CurrentEntity="CurrentEntity"
        @SaveRecord="SaveRecord" :RelatedObj="RelatedObj"
    />
    <DashOptionToggleCard v-if="col.Field.FieldType === 'Option Toggle'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord"
    />
    <DashChecklistToggleCard v-if="col.Field.FieldType === 'Checklist Toggle'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord"
    />
    <DashDateGraphic v-if="col.Field.FieldType === 'Date Graphic'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord"
    />
    <DashWriteup v-if="col.Field.FieldType === 'Writeup'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord"
    />
    
    <DashRecordCard v-if="col.Field.FieldType === 'Record Card'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit" :RelatedObj="RelatedObj"
        @SaveRecord="SaveRecord" :EntityDataRef="EntityDataRef"
    />   
    <DataImageFieldCard v-if="col.Field.FieldType === 'Data Image Field'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord" @onPhotoUpload="onPhotoUpload"
    /> 
    <DataUploadFileCard v-if="col.Field.FieldType === 'Data Upload File Field'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord" @SaveDocumentField="SaveDocumentField"
    /> 
    <QRImageFieldCard v-if="col.Field.FieldType === 'QRImageField'"
        :System="System" :SystemEntities="SystemEntities"
        :col="col" :Record="Record" :CanEdit="CanEdit"
        @SaveRecord="SaveRecord" :CurrentEntity="CurrentEntity"
    /> 
     <PhysicalAddressFieldCard :col="col" v-if="col.Field.FieldType === 'PhysicalAddressField'"
      :Record="Record" :CanEdit="CanEdit" @SaveRecord="SaveRecord" 
      :System="System" :SystemEntities="SystemEntities" 
      />
      <PostalAddressFieldCard :col="col" v-if="col.Field.FieldType === 'PostalAddressField'"
      :Record="Record" :CanEdit="CanEdit" @SaveRecord="SaveRecord" 
      :System="System" :SystemEntities="SystemEntities" 
      />
      <GoogleMapsLocationFieldCard :col="col" v-if="col.Field.FieldType === 'GoogleMapsLocation'"
      :Record="Record" :CanEdit="CanEdit" @SaveRecord="SaveRecord" 
      :System="System" :SystemEntities="SystemEntities" 
      />
  </div>
</template>
 
    
    
<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashUserAvatar from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashUserAvatar'
import DashOptionToggleCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashOptionToggleCard'
import DashChecklistToggleCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashChecklistToggleCard'

import DashDateGraphic from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashDateGraphic'
import DashWriteup from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashWriteup'
import DashRecordCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DashRecordCard'

import DataImageFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DataImageFieldCard'
import DataUploadFileCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/DataUploadFileCard'
import QRImageFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/QRImageFieldCard'
import PhysicalAddressFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/PhysicalAddressFieldCard'
import PostalAddressFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/PostalAddressFieldCard'
import GoogleMapsLocationFieldCard from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldCards/GoogleMapsLocationFieldCard'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit','EntityDataRef','RelatedObj','CurrentEntity'],
    components: {DashUserAvatar,DashOptionToggleCard,DashChecklistToggleCard,DashDateGraphic,DashWriteup,DashRecordCard,
    DataImageFieldCard,DataUploadFileCard,QRImageFieldCard,
    PhysicalAddressFieldCard,PostalAddressFieldCard,GoogleMapsLocationFieldCard},
    data() {
        return {

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        SaveDocumentField(payload){
            this.$emit('SaveDocumentField',payload)
        },
        onPhotoUpload(payload){
            this.$emit('onPhotoUpload',payload)
        },
        SaveRecord(payload){
            this.$emit('SaveRecord',payload)
        }
    }
}
</script>

<style>

</style>



