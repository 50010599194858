<template>
<div>
   <!-- <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: [field]})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn> -->
   <v-list-item class="justify-center subtleoverline">
        {{field.DisplayName}} 
    </v-list-item>
    <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Current Record' && AppURL">
        <img :id="elmntid" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+AppURL.split(' ').join('').split('firebaseapp.com').join('web.app')+'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+Record.id" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Current Record' && AppURL">
            <v-btn @click="DownloadQR(AppURL.split(' ').join('').split('firebaseapp.com').join('web.app'),'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+Record.id,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Related Record' && AppURL && field.QRLinkFieldLookup">
        <img :id="elmntid" v-if="Record[field.QRLinkFieldLookup.Name]" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+AppURL.split(' ').join('').split('firebaseapp.com').join('web.app')+'/'+field.QRLinkFieldLookup.RelatedLocation.split(' ').join('')+'/'+Record[field.QRLinkFieldLookup.Name+'id']" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Related Record' && AppURL && field.QRLinkFieldLookup">
            <v-btn @click="DownloadQR(AppURL.split(' ').join('').split('firebaseapp.com').join('web.app'),'/'+CurrentEntity.SingleName.split(' ').join('')+'/',Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Link Field' && AppURL && field.QRLinkFieldLink">
        <img :id="elmntid" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+field.QRLinkFieldLink" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Link Field' && AppURL"><v-btn @click="DownloadStaticQR(field.QRLinkFieldLink,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Static URL' && field.StaticQRURL">
        <img :id="elmntid" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+field.StaticQRURL" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-center" v-if="field.QRLinkFieldType === 'Static URL' && AppURL"><v-btn @click="DownloadStaticQR(field.StaticQRURL,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
  <!-- <v-layout class="justify-center">
    <div v-if="FieldValue" v-html="FieldValue">
    </div>
    <div v-if="!FieldValue">
        NO DATA
    </div>
  </v-layout> -->
  <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','CanEdit','CurrentEntity','elmntid'],
    components: {},
    data() {
        return {
          Editing: false,
        }
    },	
    computed:{
        AppURL(){
            return this.System && this.System.ClientAppURL ? this.System.ClientAppURL : window.location.host
        },
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      DownloadStaticQR(path,name){
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path  
      var QRUrl = RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
        DownloadQR(appurl,path,final){
            let name = this.Record[this.CurrentEntity.Primary_Field_Name]
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path+final
      var hostingURL = appurl  
      var QRUrl = hostingURL+RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },

    }
}
</script>

<style>

</style>



